var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-form",
        {
          ref: "updateAlbumRequest",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.updateAlbumRequest,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { border: "1px solid rgb(96 99 102 / 19%)" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "45%", float: "left" },
                  attrs: { label: "Loại album" },
                },
                [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.updateAlbumRequest.albumType)),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "45%", float: "right" },
                  attrs: { label: "Tên Album" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.updateAlbumRequest.albumName))])]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { clear: "both" },
                  attrs: { label: "Mô tả Album" },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.updateAlbumRequest.albumDescription)),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "max-height": "492px", overflow: "scroll" } },
                [
                  _c(
                    "h3",
                    {
                      staticStyle: {
                        "margin-left": "35px",
                        "margin-bottom": "20px",
                        "margin-top": "-39px",
                      },
                    },
                    [_vm._v("\n          Ảnh được chọn\n        ")]
                  ),
                  _vm.showProgress
                    ? _c("el-progress", {
                        staticStyle: {
                          width: "25%",
                          float: "left",
                          "margin-top": "23px",
                        },
                        attrs: {
                          percentage: _vm.percentage,
                          color: _vm.colors,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-dialog",
                    {
                      attrs: { width: "30%", visible: _vm.dialogVisible },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c("h3", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(
                      "\n          Danh sách ảnh album: " +
                        _vm._s(this.updateAlbumRequest.albumName) +
                        "\n        "
                    ),
                  ]),
                  _c("hr", {
                    staticStyle: { width: "98%", "margin-top": "-18px" },
                  }),
                  Array.isArray(_vm.updateAlbumRequest.pictureList) &&
                  _vm.updateAlbumRequest.pictureList.length
                    ? _c(
                        "el-row",
                        {
                          staticClass: "demo-image__placeholder",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                        },
                        [
                          _vm._l(
                            _vm.updateAlbumRequest.pictureList,
                            function (listPicture, index) {
                              return _c(
                                "div",
                                {
                                  key: listPicture,
                                  staticClass: "inline-block",
                                  staticStyle: {
                                    float: "left",
                                    margin: "14px",
                                    border: "1px solid #dcdfe6",
                                    width: "160px",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Xem ảnh",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "100%",
                                          height: "160px",
                                        },
                                        attrs: {
                                          src: listPicture.replace(
                                            "/Albumanh/",
                                            "/Albumanh/thumbnail_"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleShowDetailPicture(
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div"),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                width: "40%",
                                visible: _vm.dialogVisiblePic,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisiblePic = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.updateAlbumRequest.pictureList[
                                    _vm.indexSrc
                                  ],
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-top": "20px", float: "left" } }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Người đăng :"),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.updateAlbumRequest.createdBy) +
                  " |\n        " +
                  _vm._s(_vm.updateAlbumRequest.pictureApprovedNumber) +
                  "/" +
                  _vm._s(_vm.updateAlbumRequest.pictureNumber) +
                  "\n        ảnh | " +
                  _vm._s(
                    _vm._f("formatDate")(_vm.updateAlbumRequest.createdDate)
                  )
              ),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", float: "right" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/parent/album" },
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "button-over", attrs: { type: "success" } },
                    [
                      _c("i", { staticClass: "el-icon-back" }),
                      _vm._v("\n          quay lại\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }